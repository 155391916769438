// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';
import { Heading, Spacer } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import CollectionItem from '../../../components/CollectionItem/index';
import CollectionSkeleton from '../../../components/CollectionItem/skeleton';
import * as RoutePaths from '../../../utils/constants/routings';
import { loadTopCollections } from '../../../utils/requestApi/collection';
import { settings } from '../slider';

const TopCollections = () => {
  const [collections, setCollections] = useState(null);

  const handleLoadTopNFTs = async () => {
    await loadTopCollections().then((data) => {
      setCollections(data.Collections);
    });
  };

  useEffect(() => {
    handleLoadTopNFTs();
  }, []);

  return (
    <>
      {collections ? (
        collections.length >= 3 && (
          <>
            <Heading as="h4" color="white" fontWeight={800} pl={5} pb={2}>
              Trending collections 💥
            </Heading>
            <Slider {...settings}>
              {collections.map((item, index) => {
                return (
                  <Link key={index} to={`${RoutePaths.COLLECTION}/${item.Id}`}>
                    <CollectionItem collection={item}></CollectionItem>
                  </Link>
                );
              })}
            </Slider>
            <Spacer height={10}></Spacer>
          </>
        )
      ) : (
        <>
          <Heading as="h4" color="white" fontWeight={800} pl={5} pb={2}>
            Trending collections 💥
          </Heading>
          <Slider {...settings}>
            <CollectionSkeleton></CollectionSkeleton>
            <CollectionSkeleton></CollectionSkeleton>
            <CollectionSkeleton></CollectionSkeleton>
            <CollectionSkeleton></CollectionSkeleton>
            <CollectionSkeleton></CollectionSkeleton>
            <CollectionSkeleton></CollectionSkeleton>
          </Slider>
          <Spacer height={10}></Spacer>
        </>
      )}
    </>
  );
};

export default TopCollections;
