// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';
import { Heading, Spacer } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Token from '../../../components/Token/index';
import * as RoutePaths from '../../../utils/constants/routings';
import { ipfsUrl } from '../../../utils/ipfs';
import { loadTopNFTs } from '../../../utils/requestApi/token';
import { settings } from '../slider';

const TopNFTs = () => {
  const [nfts, setNfts] = useState(null);

  const handleLoadTopNFTs = async () => {
    await loadTopNFTs().then((data) => {
      setNfts(data.Nfts);
    });
  };

  useEffect(() => {
    handleLoadTopNFTs();
  }, []);

  return (
    <>
      {nfts ? (
        <>
          {nfts.length >= 3 ? (
            <>
              <Heading as="h4" color="white" fontWeight={800} pl={5} pb={2}>
                Trending NFTs 🔥
              </Heading>
              <Slider {...settings}>
                {nfts.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      to={{
                        pathname: `${RoutePaths.NFT}/${item.CollectionId}:${item.Id}`,
                        state: {
                          imageUrl: item.CachedImage
                            ? encodeURI(item.CachedImage)
                            : encodeURI(`${ipfsUrl}${item.ImageURI.replace('ipfs://', '')}`),
                        },
                      }}
                    >
                      <Token token={item} />
                    </Link>
                  );
                })}
              </Slider>
              <Spacer height={10}></Spacer>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <Heading as="h4" color="white" fontWeight={800} pl={5} pb={2}>
            Trending NFTs 🔥
          </Heading>
          <Slider {...settings}>
            <Token></Token>
            <Token></Token>
            <Token></Token>
            <Token></Token>
            <Token></Token>
            <Token></Token>
          </Slider>
          <Spacer height={10}></Spacer>
        </>
      )}
    </>
  );
};

export default TopNFTs;
