import { Box, Image, Skeleton } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { getExtension } from '../../../utils/helper';
import { ipfsUrl } from '../../../utils/ipfs';
import NFTPlayer from '../create/player';

const NFT = ({ onOpen, tokenInfo }) => {
  const location = useLocation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const nftExtension = (
    tokenInfo.animationURI
      ? getExtension(tokenInfo.animationURI)
      : getExtension(tokenInfo.cachedImageUrl ? tokenInfo.cachedImageUrl : tokenInfo.imageUrl)
  ).toLowerCase();

  return (
    <>
      {!nftExtension ? (
        <Skeleton h="600px" w="600px" borderRadius="4px" justifySelf="center" />
      ) : (
        <>
          {nftExtension === 'mp4' ? (
            <Box
              maxH="600px"
              // h={{ base: '300px', lg: '600px' }}
              // w={{ base: '300px', lg: '600px' }}
              borderRadius="4px"
              // justifySelf="center"
            >
              <NFTPlayer
                url={
                  tokenInfo.cachedAnimationImage
                    ? tokenInfo.cachedAnimationImage
                    : ipfsUrl + tokenInfo.animationURI.replace('ipfs://', '')
                }
              />
            </Box>
          ) : (
            <>
              {((!imageLoaded && location.state && !location.state.imageUrl !== null) || imageError) && (
                <Skeleton h="600px" w="600px" borderRadius="4px" justifySelf="center" />
              )}
              <Box
                maxH="600px"
                display={imageLoaded ? 'grid' : 'none'}
                cursor="pointer"
                onClick={onOpen}
                borderRadius="4px"
                justifySelf="center"
              >
                <Image
                  maxH="600px"
                  name={tokenInfo.name}
                  onLoad={() => setImageLoaded(true)}
                  onError={() => setImageError(true)}
                  src={
                    location.state && location.state.imageUrl !== null
                      ? location.state.imageUrl
                      : tokenInfo.cachedImageUrl !== ''
                      ? tokenInfo.cachedImageUrl
                      : tokenInfo.imageUrl.replace('ipfs://', '')
                  }
                />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default NFT;
