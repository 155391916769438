import styled from 'styled-components';

export const Title = styled.div`
  font-size: 45px;
  font-family: 'SpaceMono';
`;

export const Description = styled.div`
  margin: 0 auto;
  font-size: 30px;
  max-width: 600px;
  margin-bottom: 10px;
`;
