import { Box, Flex, Text } from '@chakra-ui/react';
import { Field } from 'formik';
import React from 'react';
import { ReactComponent as CloseImg } from '../../../assets/images/icons/close.svg';
import CustomFileInput from '../../../ui-kit/CustomField/FileInput';
import Error from '../../../ui-kit/Error/index';
import { UploadedCoverImageFullSize } from '../../Marketplaces/styled-ui';
import NFTPlayer from './player';

const NFTField = ({ values, setFieldValue, errors, touched, setFieldError }) => {
  const nftCover = values.nftCover[0];
  const nftType = values.nftImage[0] ? values.nftImage[0].type : null;
  const NFT_VIDEO_TYPE = 'video/mp4';

  const [nft, setNFT] = React.useState();

  React.useEffect(() => {
    if (values.nftImage[0] && values.nftImage[0] !== nft) {
      setNFT(URL.createObjectURL(values.nftImage[0]));
    }
  }, [values.nftImage[0]]);

  return (
    <>
      {!nft || !values.nftImage[0] ? (
        <Box>
          <Field
            id="nftImage"
            name="nftImage"
            component={CustomFileInput}
            onChange={(file) => {
              if (file[0] && file[0].type === NFT_VIDEO_TYPE) setFieldValue('isVideoNFT', true);
            }}
            type="text"
            accept=".png, .jpg, .jpeg, .gif, .webp, .mp4"
            label="Upload file"
            labelTypes="JPG, JPEG, PNG, GIF, WEBP, MP4. Max 100mb."
          />
          {errors.nftImage && touched.nftImage && <Error>{errors.nftImage}</Error>}
        </Box>
      ) : (
        <Box>
          <Text mb={2}> Upload file</Text>
          {nftType !== NFT_VIDEO_TYPE ? (
            <>
              <Flex w="full" h="full" pb={6} flexDirection="column" alignContent="center">
                <Box alignSelf="center" pb={2}>
                  <UploadedCoverImageFullSize src={URL.createObjectURL(values.nftImage[0])} borderRadius={4} />
                </Box>

                <Flex color="gray.900" justifyContent="space-between" alignItems="center" pt={1}>
                  <Box color="white" pr={4}>
                    {values.nftImage[0].name}
                  </Box>
                  <CloseImg onClick={() => setFieldValue('nftImage', '')} />
                </Flex>
              </Flex>
            </>
          ) : (
            <>
              <Flex w="full" h="full" flexDirection="column" alignContent="center">
                <div className="player-wrapper">
                  <NFTPlayer
                    url={nft}
                    setFieldError={setFieldError}
                    setFieldValue={setFieldValue}
                    fieldName="nftImage"
                  />
                </div>
                <Flex color="gray.900" justifyContent="space-between" alignItems="center" pt={1}>
                  <Box color="white" pr={4}>
                    {values.nftImage[0].name}
                  </Box>
                  <CloseImg
                    onClick={() => {
                      setFieldValue('nftImage', '');
                      setFieldValue('isVideoNFT', false);
                      setFieldValue('nftError', false);
                    }}
                  />
                </Flex>

                <Box>{errors.nftImage && <Error>{errors.nftImage}</Error>}</Box>
                <Box>{errors.nftError && <Error>{errors.nftError}</Error>}</Box>

                {!nftCover ? (
                  <Box pt={4}>
                    <Field
                      id="nftCover"
                      name="nftCover"
                      component={CustomFileInput}
                      type="text"
                      accept=".png, .jpg, .jpeg, .gif, .webp,"
                      label="Upload cover"
                      labelTypes="JPG, JPEG, PNG, GIF, WEBP, Max 100mb."
                    />
                    {errors.nftCover && touched.nftCover && <Error>{errors.nftCover}</Error>}
                  </Box>
                ) : (
                  <>
                    <Text mb={2} pt={4}>
                      Upload cover
                    </Text>
                    <Flex w="full" h="full" pb={6} flexDirection="column" alignContent="center">
                      <Box alignSelf="center" pb={2}>
                        <UploadedCoverImageFullSize src={URL.createObjectURL(nftCover)} borderRadius={4} />
                      </Box>

                      <Flex color="gray.900" justifyContent="space-between" alignItems="center">
                        <Box color="white" pr={4}>
                          {nftCover.name}
                        </Box>
                        <CloseImg onClick={() => setFieldValue('nftCover', '')} />
                      </Flex>
                    </Flex>
                  </>
                )}
              </Flex>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default React.memo(NFTField);
