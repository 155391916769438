import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { backendUrl } from '../constants/variables';

export default function useAuth() {
  let history = useHistory();
  const [error, setError] = useState(null);

  const loginUser = async (data) => {
    const { username, password } = data;
    return axios
      .post(`${backendUrl}sojasfojasfs/`, {
        Username: username,
        Password: password,
      })
      .then(async (responce) => {
        localStorage.setItem('jwtToken', responce.data.token);
        window.location.replace('/');
      })
      .catch((err) => {
        console.log('err', err);
        localStorage.removeItem('jwtToken');
        history.push('auth/login');
        setError('Error');
      });
  };

  return {
    loginUser,
    error,
  };
}
