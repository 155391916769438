import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import BidsHistory from '../../../components/BidHistory/index';
import NFTOwnersHistory from '../../../components/NFTOwnersHistory/index';
import { AUCTION_TYPE } from '../../../utils/constants/variables';

const NFTHistory = ({ NFTOwners, data, pastEvents, tokenInfo }) => {
  const isAuction = tokenInfo.listingInfo.listingType === AUCTION_TYPE && pastEvents && pastEvents.length > 0;

  return (
    <>
      {(NFTOwners.length > 0 || isAuction) && (
        <Tabs isLazy colorScheme="teal" color="teal.200" pt={4}>
          <TabList>
            {isAuction && <Tab>Bids</Tab>}
            {NFTOwners.length > 0 && <Tab>History</Tab>}
          </TabList>
          <TabPanels>
            {isAuction && (
              <TabPanel padding={'20px 0px'}>
                <BidsHistory
                  bidsProps={pastEvents.sort((a, b) => b.blockNumber - a.blockNumber)}
                  account={data.account.address}
                />
              </TabPanel>
            )}
            {NFTOwners.length > 0 && (
              <TabPanel padding={'20px 0px'}>
                {NFTOwners && <NFTOwnersHistory ownersProps={NFTOwners} tokenInfo={tokenInfo} />}
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};

export default NFTHistory;
