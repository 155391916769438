import moment from 'moment';
import AvatarSrc from '../assets/images/avatar-1.png';
import Avatar2Src from '../assets/images/avatar-2.jpg';
import BackgroundSrc1280 from '../assets/images/header-1280.png';
import BackgroundSrc1920 from '../assets/images/header-1920.png';
import BackgroundSrc2560 from '../assets/images/header-2560.png';
import Background2Src1280 from '../assets/images/header2-1280.png';
import { rabbitsCollectionContractAddress, stampsCollectionContractAddress } from '../utils/constants/variables';
import { nullAddress } from './constants/variables';

export const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

export const shortAddress = (address) => {
  if (address == null) return nullAddress;
  const firstPart = address.substring(0, 6);
  const secondPart = address.slice(-4);
  return firstPart + '...' + secondPart;
};

export const getDuration = (startDate, endDate) => {
  const startDateUnix = moment.utc(startDate).unix();
  const endDateUnix = moment.utc(endDate).unix();
  return (endDateUnix - startDateUnix).toString();
};

export const getExtension = (name) => {
  return name.match(/\.([^.]+)$/)?.[1];
};

export const getExternalCollectionData = (address) => {
  switch (address) {
    case rabbitsCollectionContractAddress:
      return {
        address: rabbitsCollectionContractAddress,
        name: 'Rad Rabbits',
        description:
          'Rad Rabbits is an exclusive collection of 8888 AI-Generated NFT Avatars living on the Moon Rabbit Metachain. Each Rabbit sports it’s own unique personality and is cursed with a limited lifespan. Care for them and deliver them from old age, or let them wither away and perish for your own amusement. Let’s get Radical!',
        symbol: 'RRC',
        avatarImg: AvatarSrc,
        backgraundImg: {
          md: BackgroundSrc1280,
          xl: BackgroundSrc1920,
          '2xl': BackgroundSrc2560,
        },
      };
    case stampsCollectionContractAddress:
      return {
        address: stampsCollectionContractAddress,
        name: 'Historical Stamp Project ',
        description: '726 Unique Postage 3D Stamps on Moon Rabbit Metaverse!',
        symbol: 'STAMP',
        avatarImg: Avatar2Src,
        backgraundImg: {
          md: Background2Src1280,
          xl: Background2Src1280,
          '2xl': Background2Src1280,
        },
        twitter: 'https://twitter.com/StampProjectNFT',
      };
    default:
      break;
  }
};
