import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import CollectionList from '../../components/CollectionList/index';
import { useSearchCollection } from '../../utils/hooks/useSearch';

const SearchCollectionsTab = ({ setCollectionsCount }) => {
  let history = useHistory();
  let location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState('');
  const [tabIndex, setTabIndex] = React.useState(0);

  const onChange = () => {
    setQuery(queryString.parse(location.search).query);
    setPageNumber(1);
  };

  const { collections, collectionCount, hasMore, loading, error } = useSearchCollection({
    query: queryString.parse(location.search).query,
    pageNumber,
    onChange,
  });

  React.useEffect(() => {
    setCollectionsCount(collectionCount);
  }, [collectionCount]);

  return (
    <div>
      <CollectionList collections={collections} setPageNumber={setPageNumber} hasMore={hasMore} loading={loading} />
    </div>
  );
};

export default SearchCollectionsTab;
