import { TimeIcon } from '@chakra-ui/icons';
import { Avatar, Box, Flex, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Pagination from '../../ui-kit/Pagination/index';
import * as RoutePaths from '../../utils/constants/routings';
import { CREATOR, FIX_TYPE, TOKEN_NAME } from '../../utils/constants/variables';
import { shortAddress } from '../../utils/helper';

const NFTOwnersHistory = ({ ownersProps, account, tokenInfo }) => {
  const [pagination, setPagination] = useState({
    currentOwners: ownersProps,
    currentPage: null,
    totalPages: null,
    totalCount: ownersProps.length,
  });
  const [ownersInfo, setOwnersInfo] = useState({ owners: null, totalOwners: 0 });
  const pageLimit = 8;
  let history = useHistory();

  useEffect(() => {
    if (ownersProps.length > 0) {
      setOwnersInfo({ owners: ownersProps, totalOwners: ownersProps.length });
    }
  }, [ownersProps]);

  useEffect(() => {
    onPageChanged({ currentPage: 1 });
  }, [ownersInfo]);

  const onPageChanged = (data) => {
    const { currentPage, totalPages } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentOwners = ownersProps.slice(offset, offset + pageLimit);
    const totalCount = ownersProps.length;

    setPagination({
      currentOwners,
      currentPage,
      totalPages,
      totalCount,
    });
  };

  if (pagination.currentOwners.length === 0) return null;

  return (
    <>
      <Box bg={'#282828'} borderRadius="12px" overflow="hidden">
        <Table size="sm">
          <Thead bgColor="gray.50" height="40px" bg="#191d1c">
            <Tr>
              <Th borderColor="transparent">
                <Flex justifyItems="center" color="white">
                  <Box pr={1}>
                    <TimeIcon color="gray.700" />{' '}
                  </Box>
                  Buyers
                </Flex>
              </Th>
              <Th borderColor="transparent"></Th>
            </Tr>
          </Thead>
          <Tbody bg={'#282828'} opacity="0.87" color="white">
            {pagination.currentOwners.map((owner) => {
              return (
                <Tr key={owner.blockNumber}>
                  <Td borderColor="gray.700">
                    <Box display="flex" alignItems="center">
                      <Avatar size="md" src="https://bit.ly/broken-link" />
                      <Box ml="3">
                        <Text
                          fontWeight={500}
                          fontSize={14}
                          cursor="pointer"
                          onClick={() => history.push(`${RoutePaths.PROFILE_PAGE}/${owner.address}`)}
                        >
                          {!owner.address ? shortAddress(tokenInfo.listingInfo.creator) : shortAddress(owner.address)}
                          {owner.address === account && '(YOU)'}
                        </Text>
                        <Text color="gray.400" fontSize="sm">
                          {owner.timestamp}
                          {owner.type === CREATOR ? CREATOR : owner.type === FIX_TYPE ? ', Fix Price' : ', Auction'}
                        </Text>
                      </Box>
                    </Box>
                  </Td>
                  <Td textAlign="end" borderColor="gray.700">
                    {owner.type !== CREATOR ? `${owner.amount} ${TOKEN_NAME}` : ''}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
          {pagination.totalCount > pageLimit && (
            <TableCaption height="55px" width="100%">
              <Pagination
                totalRecords={pagination.totalCount}
                pageLimit={pageLimit}
                pageNeighbours={0}
                onPageChanged={onPageChanged}
              />
            </TableCaption>
          )}
        </Table>
      </Box>
    </>
  );
};

export default NFTOwnersHistory;
