export const networkName = 'MoonRabbit';
export const backendUrl = 'https://backend.nft.moonrabbit.com/';
// export const backendUrl = 'https://testbackend.hashlab.online/';
export const prodUrl = 'https://dev.hashlab.online/';
export const chainId = '0x500';
// export const chainId = '0x4';
export const marketplaceContractAddress = '0xb3Ba1D4E935F0d917E1eC7d19Ca657e29F4Ada5A';
export const rabbitsCollectionContractAddress = '0x073A3d7E2D77a2a3f9bd7D0535971e7951a1a22b';
export const stampsCollectionContractAddress = '0x7882Ffea6f2d917dA88e8F12901F5aC3AF537d6A';

export const TOKEN_NAME = 'AAA';
export const minStep = 1000;
export const rpcUrl = 'https://evm.moonrabbit.com';
// export const rpcUrl = 'https://rinkeby.infura.io/v3/';

export const nullAddress = '0x0000000000000000000000000000000000000000';
export const AUCTION_TYPE = 'Auction';
export const FIX_TYPE = 'FixedPrice';

export const CREATOR = 'Creator';

export const TokenSaleStatus = {
  SALE: 'SALE',
  STORAGE: 'STORAGE',
  USER_PROFILE: 'USER_PROFILE',
  SECONDARY_MARKET: 'SECONDARY_MARKET',
};

export const FIX_PRICE_NFT = 'FIX_PRICE_NFT';
export const AUCTION_PRICE_NFT = 'AUCTION_PRICE_NFT';
export const isMaintenance =  false;
