import { Box, Button, Container, Flex, Heading, Stack } from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Error from '../../ui-kit/Error/index';
import CustomInput from '../../ui-kit/Input/index';
import UserContext from '../../utils/contexts/User';
import useAuth from '../../utils/hooks/useAuth';
import { FormGrid } from '../Marketplaces/styled-ui';
import { LoginSchema } from './validation';

export default function LoginPage() {
  let history = useHistory();
  const { loginUser, error } = useAuth();
  const [formData, setFormData] = useState('');
  const data = React.useContext(UserContext);
  const { address, updateUserData } = data.account;

  const handleLogin = async () => {
    await loginUser(formData.values);
    updateUserData();
  };

  const initialValues = {
    username: '',
    password: '',
  };

  React.useEffect(() => {
    if (formData !== '') handleLogin();
  }, [formData]);

  const onSubmitHandler = React.useCallback((values, actions) => {
    setFormData({ values, actions });
  }, []);

  if (localStorage.getItem('jwtToken')) {
    history.push('/');
  }

  return (
    <Flex align={'center'} justify={'center'}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Box p={8}>
          <Stack spacing={4}>
            <Formik
              initialValues={initialValues}
              validationSchema={LoginSchema}
              validateOnChange={false}
              onSubmit={onSubmitHandler}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <>
                  <Container pt={16} pb="100px">
                    <Heading color="white" textAlign="center">
                      SIGN IN
                    </Heading>
                    <Box
                      maxWidth="588px"
                      margin="0 auto"
                      padding={8}
                      display="flex"
                      justifyContent="center"
                      maxHeight="100vh"
                    >
                      <Box w="384px">
                        <FormGrid onSubmit={handleSubmit}>
                          <Box>
                            <Field
                              id="username"
                              component={CustomInput}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              name="username"
                              value={values.username}
                              autoComplete="off"
                              label="Username"
                            />
                            {errors.username && touched.username && <Error>{errors.username}</Error>}
                          </Box>

                          <Box>
                            <Field
                              id="password"
                              component={CustomInput}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="password"
                              name="password"
                              value={values.password}
                              autoComplete="off"
                              label="Password"
                            />
                            {errors.password && touched.password && <Error>{errors.password}</Error>}
                          </Box>

                          <Button
                            id="login"
                            type="submit"
                            disabled={isSubmitting}
                            variant="solid"
                            bg="teal.200"
                            size="lg"
                            colorScheme="black"
                            color="black"
                            isLoading={isSubmitting}
                            loadingText="Login..."
                            mb={20}
                          >
                            Login
                          </Button>
                        </FormGrid>
                      </Box>
                    </Box>
                  </Container>
                </>
              )}
            </Formik>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
