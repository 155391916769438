import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import TokenList from '../../components/TokenList/index';
import { useSearchNFT } from '../../utils/hooks/useSearch';

const SearchNftTab = ({ setTokensCount }) => {
  let history = useHistory();
  let location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState('');
  const [tabIndex, setTabIndex] = React.useState(0);
  const [collectionsCount, setCollectionsCount] = React.useState(0);

  const onChange = () => {
    setQuery(queryString.parse(location.search).query);
    setPageNumber(1);
  };
  const { tokens, tokenCount, hasMore, loading, error } = useSearchNFT({
    query: queryString.parse(location.search).query,
    pageNumber,
    onChange,
  });

  const handleSortMenu = async (value) => {
    setQuery(value);
    setPageNumber(1);
  };

  React.useEffect(() => {
    setTokensCount(tokenCount);
  }, [tokenCount]);

  return (
    <div>
      <TokenList
        tokens={tokens}
        handleSort={handleSortMenu}
        loading={loading}
        setPageNumber={setPageNumber}
        hasMore={hasMore}
        error={error}
        headerVisability={false}
      />
    </div>
  );
};

export default SearchNftTab;
