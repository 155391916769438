import React from 'react';
import ReactPlayer from 'react-player/lazy';
import './style.css';

const NFTPlayer = ({ url, setFieldError, setFieldValue, fieldName }) => {
  return (
    <>
      <ReactPlayer
        url={url}
        className="react-player"
        playing
        controls
        width="100%"
        height="600px"
        onError={(e) => {
          if (setFieldValue) {
            setFieldValue('nftError', true);
            setFieldError(fieldName, 'Incorrect file');
          }
          console.log(e);
        }}
      />
    </>
  );
};

export default React.memo(NFTPlayer);
