import { StarIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';

const StarButton = ({ isFavorite, addToFavorite }) => {
  // const handleAddToFavorite = (address, explore) => {
  //   addToFavoriteCollection({ address, explore }).then((data) => {
  //     setFavorite(!favorite);
  //   });
  // };

  // const handleAddToFavorite = () => {
  //   addToFavorite().then((data) => {
  //     setFavorite(!favorite);
  //   });
  // };

  return (
    <>
      {localStorage.getItem('jwtToken') && (
        <Button
          _hover={{ bg: 'transparent' }}
          mr="4px"
          variant="outline"
          colorScheme="yellow"
          color="yellow.400"
          onClick={(e) => {
            e.preventDefault();
            addToFavorite();
          }}
        >
          <StarIcon color={isFavorite ? 'yellow.400' : '#ccc'} />
        </Button>
      )}
    </>
  );
};

export default StarButton;
