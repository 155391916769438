import { Box } from '@chakra-ui/layout';
import { Avatar, Badge, Flex, Image, Skeleton, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';

import TwitterSrc from '../../assets/images/icons/twitter.png';
import StarButton from '../../components/CollectionItem/starButton';

import TokenList from '../../components/TokenList/index';
import * as RoutePaths from '../../utils/constants/routings';
import { rabbitsCollectionContractAddress, stampsCollectionContractAddress } from '../../utils/constants/variables';
import { getExternalCollectionData, shortAddress } from '../../utils/helper';
import useTokenCollectionList from '../../utils/hooks/useTokenCollectionList';
import { ipfsUrl } from '../../utils/ipfs';
import { addToFavoriteCollection } from '../../utils/requestApi/collection';
import { InfoWrapper } from '../NFT/styled-ui';

const CollectionDetails = () => {
  let history = useHistory();
  const { address } = useParams();

  const [imageLoaded, setImageLoaded] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState('');
  const { tokens, count, collection, hasMore, loading, error } = useTokenCollectionList({ query, pageNumber, address });
  const [favorite, setFavorite] = useState(collection ? collection.IsExplore : false);
 
  const handleSortMenu = async (value) => {
    setQuery(value);
    setPageNumber(1);
  };

  const isRabbitCollection =
    rabbitsCollectionContractAddress === address || stampsCollectionContractAddress === address;

  const rabbitCollection = getExternalCollectionData(address);

  const handleAddToFavoriteCollection = () => {
    addToFavoriteCollection({ collectionAddress: address, explore: favorite }).then((data) => {
      setFavorite(!favorite);
    });
  };

  return (
    <>
      {collection && (
        <>
          <Helmet>
            <title>
              {!isRabbitCollection
                ? collection.Name + ' Moon Rabbit NFT Marketplace'
                : rabbitCollection.name + ' Moon Rabbit NFT Marketplace'}
            </title>
            <meta name="description" content={collection.Description} />
            <meta property="og:title" content={collection.Name + ' Moon Rabbit NFT Marketplace'} />
            <meta property="og:description" content={collection.Description} />

            <meta
              property="og:image"
              content={
                !isRabbitCollection && collection.BackgroundURI !== ''
                  ? ipfsUrl + collection.BackgroundURI.replace('ipfs://', '')
                  : rabbitCollection.backgraundImg
              }
            />
          </Helmet>
          <Box position="relative">
            {!imageLoaded && !isRabbitCollection && <Skeleton h={280} borderRadius="4px" justifySelf="center" />}
            <Box
              display={imageLoaded || isRabbitCollection ? 'grid' : 'none'}
              bgSize="cover"
              h={280}
              bgPosition="center"
              bgImage={
                !isRabbitCollection && collection.BackgroundURI !== ''
                  ? ipfsUrl + collection.BackgroundURI.replace('ipfs://', '')
                  : rabbitCollection.backgraundImg
              }
            ></Box>
            <Image
              bgPosition="center"
              display={'none'}
              src={
                !isRabbitCollection && collection.BackgroundURI !== ''
                  ? ipfsUrl + collection.BackgroundURI.replace('ipfs://', '')
                  : rabbitCollection.backgraundImg
              }
              layout={'fill'}
              onLoad={() => setImageLoaded(true)}
            />

            <Box maxW="1536px" margin="0 auto" p={10} position="absolute" left={0} right={0} bottom={-120}>
              <Box display="flex" justifyContent="center">
                <Flex maxW="588px" minW={{ base: '100%', lg: '588px' }} pr={4} pl={4}>
                  <InfoWrapper>
                    <Avatar
                      size="xl"
                      src={
                        !isRabbitCollection && collection.AvatarURI !== ''
                          ? ipfsUrl + collection.AvatarURI.replace('ipfs://', '')
                          : rabbitCollection.avatarImg
                      }
                    />
                    <Box ml="3">
                      <Flex flexFlow={'row'} gridGap={4}>
                        <Text fontWeight={800} fontSize={24} color="white">
                          {!isRabbitCollection ? collection.Name : rabbitCollection.name}
                        </Text>

                        <StarButton isFavorite={favorite} addToFavorite={() => handleAddToFavoriteCollection()} />
                      </Flex>
                      {tokens && tokens[0] && (
                        <Badge
                          cursor={!isRabbitCollection ? 'pointer' : 'default'}
                          onClick={(e) =>
                            !isRabbitCollection
                              ? history.push({
                                  pathname: `${RoutePaths.PROFILE_PAGE}/${collection.Owner}`,
                                  state: {
                                    tab: 'nfts',
                                    page: 'home',
                                  },
                                })
                              : e.preventDefault()
                          }
                        >
                          Collection by{' '}
                          {/* {!isRabbitCollection && tokens[0].CreatorAddress !== nullAddress
                            ? shortAddress(tokens[0].CreatorAddress)
                            : 'Moon Rabbit'} */}

                          {!isRabbitCollection 
                            ? shortAddress(collection.Owner)
                            : 'Moon Rabbit'}
                        </Badge>
                      )}

                      <Text color="gray.200" fontSize={12}>
                        {!isRabbitCollection ? collection.Description : rabbitCollection.description}
                      </Text>
                      {isRabbitCollection && rabbitCollection.twitter && (
                        <a href={rabbitCollection.twitter} target="_blank">
                          <Flex flexFlow={'row'} alignItems="center" gridGap={1} pt={1}>
                            <Image src={TwitterSrc} w="16px" h={'16px'} />
                            <Text color={'gray.400'} fontSize="12px">
                              Twitter
                            </Text>
                          </Flex>
                        </a>
                      )}
                    </Box>
                  </InfoWrapper>
                </Flex>
              </Box>
            </Box>
          </Box>

          <Box pt={{ base: 140, sm: 340, md: 240, lg: 120 }}>
            <Box minH={'300px'} maxW="1536" margin="0 auto" pr={10} pl={10} pb={150}>
              <TokenList
                tokens={tokens}
                handleSort={handleSortMenu}
                loading={loading}
                setPageNumber={setPageNumber}
                hasMore={hasMore}
                address={address}
                error={error}
                headerTitle={`NFTs ${tokens && `(${count})`}`}
                headerVisability
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default CollectionDetails;